import React from 'react';
import Helmet from 'react-helmet';
import { Link as GatsbyLink } from 'gatsby';
import styled from 'styled-components';

import Heading from '@nib-components/heading';
import Card from '@nib/card';
import { Section, Stack, Columns, Column, Box, Inline } from '@nib/layout';
import Link from '@nib-components/link';
import Copy, { Bold } from '@nib-components/copy';
import { SecondaryButton } from '@nib-components/button';
import { colorTrueGreen } from '@nib-components/theme';

import desktop from '../img/about-nib/hero/desktop.jpg';
import desktop2x from '../img/about-nib/hero/desktop@2x.jpg';
import mobile from '../img/about-nib/hero/mobile.jpg';
import mobile2x from '../img/about-nib/hero/mobile@2x.jpg';
import tablet from '../img/about-nib/hero/tablet.jpg';
import tablet2x from '../img/about-nib/hero/tablet@2x.jpg';

import HeroPanel from '../components/HeroPanel';
import Layout from '../components/Layout';
import metrics from '../metrics';
import UtilityButtons from '../components/UtilityButtons';
import { UtilityLinkModel } from '../constructs/models';
import {
  BackgroundImageWrapper,
  Icon,
  Wrapper,
  ContentWrapper,
  HeroPanelCopy,
} from '../components/styledComponents';

import diversity from '../img/about-nib/diversity.jpg';
import teTiritiOWaitangi from '../img/about-nib/te-tiriti-o-waitangi.jpg';
import careers from '../img/about-nib/careers.jpg';

import { urlConstants } from '../constructs/constants';

import specsavers from '../img/about-nib/partner-icons/specsavers.jpg';
import tbiHealth from '../img/about-nib/partner-icons/tbi-health.jpg';
import tend from '../img/about-nib/partner-icons/tend.jpg';
import zoomPharmacy from '../img/about-nib/partner-icons/zoom-pharmacy.jpg';
import mrLogo from '../img/about-nib/partner-icons/MRlogo.svg';

const HeroPanelSection = (): JSX.Element => {
  return (
    <HeroPanel
      title="About nib"
      variation="condensed"
      images={{
        desktop: desktop,
        desktop2x: desktop2x,
        mobile: mobile,
        mobile2x: mobile2x,
        tablet: tablet,
        tablet2x: tablet2x,
      }}
    >
      <HeroPanelCopy>We’re your partner in health and wellbeing.</HeroPanelCopy>
    </HeroPanel>
  );
};

const AboutUsSection = (): JSX.Element => {
  return (
    <Section role="section">
      <Stack space={4}>
        <Heading color="trueGreen" size={{ sm: 4, md: 3, lg: 2 }} component="h2">
          A bit about us
        </Heading>
        <Copy measure={false}>
          Our purpose is your better health. We’re here to help Kiwis and their families live
          healthier, happier lives, and to help local businesses who want to protect their
          employees. We do this by offering insurance that’s easy to use and affordable while also
          helping connect our members to the right tools and services to improve their health and
          wellbeing.
        </Copy>
        <Copy measure={false}>
          Our New Zealand business is part of the{' '}
          <Link href="https://www.nib.com.au/about-us">nib Group</Link> - a trusted international
          health partner that provides health insurance to over 1.4 million Australian and New
          Zealand residents. We began partnering with Kiwis after nib Group acquired Tower Medical
          Insurance Limited in 2012 and went on to acquire the OnePath medical business in 2015.
        </Copy>
        <Copy measure={false}>
          In 2018 we launched a first of its kind Māori population health initiative with Auckland
          iwi, Ngāti Whātua Ōrākei, which provides private health insurance and health management
          programmes for their members. nib’s Kaiarāhi (health navigators) work with iwi members on
          finding the right health options that work for them.
        </Copy>
        <Copy measure={false}>
          In 2022 we acquired Kiwi Insurance Limited which allows us to provide greater value to our
          members by giving them access to a suite of life and living insurance covers, in addition
          to health cover.
        </Copy>
        <Copy measure={false}>
          To meet our purpose, we also support a whole range of events, organisations, initiatives
          and health-related programmes including being a major partner of the Blues and the nib
          Blues women’s rugby team.
        </Copy>
      </Stack>
    </Section>
  );
};

interface LinkTileShape {
  title: string;
  href: string;
  bodyText: string;
}

interface LinkTileRowShape {
  linkTiles: LinkTileShape[];
}

const LinkTilesSection = (): JSX.Element => {
  const linkTilesTop: LinkTileShape[] = [
    {
      title: 'Our history',
      bodyText: `nib Group have been supporting the health needs of our members for over 65 years`,
      href: 'https://www.nib.com.au/shareholders/company-profile/company-history',
    },
    {
      title: 'Sustainability',
      bodyText: `Through our success we aspire to more prosperous and sustainable communities`,
      href: 'https://www.nib.com.au/shareholders/company-profile/sustainability',
    },
    {
      title: 'Climate-Related Disclosure',
      bodyText: `To view our Climate-Related Disclosure click here`,
      href: 'https://www.nib.com.au/docs/2024-nib-new-zealand-climate-related-disclosure',
    },
    {
      title: 'In the community',
      bodyText: `We partner with a range of organisations and charities to promote healthy and active lifestyles for our members, employees and wider communities`,
      href: '/about-nib/nib-in-the-community',
    },
  ];
  const linkTilesBottom: LinkTileShape[] = [
    {
      title: 'Financial advice and our financial strength rating',
      bodyText: `We’re committed to achieving and demonstrating the highest standards of financial advice and compliance`,
      href: '/about-nib/financial-advice',
    },
    {
      title: 'Directors',
      bodyText: `Meet our Board of Directors`,
      href: '/about-nib/directors',
    },
    {
      title: 'News and media',
      bodyText: `Check out our latest media releases and insights from our people`,
      href: '/news-and-media',
    },
    {
      title: 'Fair Conduct Programme',
      bodyText: `We’re committed to fairness, transparency, and ethical conduct in everything we do. To view our Fair Conduct Programme, click here.`,
      href: 'https://assets.ctfassets.net/ja9v5o5o08yv/45Am57trAJa92Y7czTojfm/6296cd062c0330402b7711713ab9ca1b/643525-1_0225_NIBNZ092_Fair_Conduct_Program_doc_D5_WEB_-_FINAL_VERSION.pdf',
    },
  ];

  return (
    <Section role="section" background="warmWhite60">
      <Stack space={4}>
        <LinkTileRow linkTiles={linkTilesTop} />
        <LinkTileRow linkTiles={linkTilesBottom} />
      </Stack>
    </Section>
  );
};

const StyledCard = styled(Card)`
  h3 {
    color: ${colorTrueGreen};
  }
`;

const LinkTileRow = (props: LinkTileRowShape): JSX.Element => {
  const { linkTiles } = props;

  return (
    <Columns space={4} collapseBelow="md">
      {linkTiles.map((linkTile, i) => {
        const { title, bodyText, href } = linkTile;
        return (
          <Column flex={true} key={i}>
            <StyledCard align="center" component={GatsbyLink} to={href}>
              <Card.Content title={title}>
                <Copy align="center">{bodyText}</Copy>
              </Card.Content>
            </StyledCard>
          </Column>
        );
      })}
    </Columns>
  );
};

const StyledHeading = styled(Heading)`
  font-weight: normal;
`;

const PartnersSection = (): JSX.Element => {
  // TODO: get link
  return (
    <Section role="section">
      <Stack space={5}>
        <StyledHeading size={{ sm: 4, md: 3, lg: 2 }} component="p" align="center">
          As an nib member you get more than just great health insurance. Learn more about how you
          can access tools and <Link href="/health-and-wellbeing">special offers</Link> through
          providers we work with to help you take control of your health, happiness and lifestyle.
        </StyledHeading>
        <Inline align="center" space={7} verticalAlign="center">
          <Icon src={tend} alt="Tend" title="Tend" />
          <Icon src={specsavers} alt="Specsavers" title="Specsavers" />
          <Icon src={tbiHealth} alt="TBI Health" title="TBI Health" />
          <Icon src={zoomPharmacy} alt="Zoom Pharmacy" title="Zoom Pharmacy" />
          <Icon src={mrLogo} alt="Mercy Radiology" title="Mercy Radiology" />
        </Inline>
      </Stack>
    </Section>
  );
};

const CareersSection = (): JSX.Element => {
  return (
    <Section role="section" background="trueGreen">
      <Wrapper>
        <ContentWrapper>
          <Stack space={{ xs: 2, md: 4 }}>
            <Copy measure={false} color="white">
              We're committed to providing equal employment opportunities and a healthy and
              supportive work environment. Want to be part of our team? Ready to help provide Kiwis
              with access to better health outcomes? Check out our job opportunities.
            </Copy>
            <SecondaryButton href={urlConstants.careers} color="light">
              Visit nib careers
            </SecondaryButton>
          </Stack>
        </ContentWrapper>
        <BackgroundImageWrapper backgroundImage={careers} />
      </Wrapper>
    </Section>
  );
};

const NavigationSection = (): JSX.Element => {
  const utilityLinks: UtilityLinkModel[] = [
    {
      subtitle: 'Looking for cover?',
      title: 'Get a quote',
      url: urlConstants.getQuickQuote(),
    },
    {
      subtitle: 'Want to compare?',
      title: 'Check our plans',
      url: '/compare-plans',
    },
    {
      subtitle: 'New to health insurance?',
      title: 'Find out more',
      url: '/health-insurance',
    },
    {
      subtitle: 'Who are our members?',
      title: 'View their stories',
      url: '/member-stories',
    },
  ];

  return (
    <Section role="section" background="warmWhite60">
      <UtilityButtons buttons={utilityLinks} flex={true} />
    </Section>
  );
};

const CommitmentToDiversitySection = (): JSX.Element => (
  <Section role="section" background="sunsetPink60">
    <Stack space={{ sm: 2, md: 4, lg: 7 }}>
      <Heading size={{ sm: 4, md: 3, lg: 2 }} component="p" color="trueGreen" align="center">
        We believe diversity and inclusion create stronger, more connected workplaces and
        communities, helping us to better recognise and meet the needs of our members.
      </Heading>
      <Wrapper>
        <ContentWrapper>
          <Stack space={{ xs: 2, md: 4 }}>
            <Box marginBottom={4}>
              <Heading size={3} color="trueGreen">
                Our commitment to diversity
              </Heading>
            </Box>
            <Copy measure={false}>
              Our commitment to diversity and inclusion is captured in our Diversity and Inclusion
              Action Plan. We believe feeling included for who we are and empowered to be our
              authentic selves is essential to wellbeing and living a fulfilling life. Our vision is
              to foster a sense of community where everyone is welcome, contributes and belongs.
            </Copy>
            <Copy measure={false}>
              Here in New Zealand, nib has joined the Superdiversity Institute and received their
              CQTick for cultural intelligence. We are proud of the diversity in our team,
              highlights include:
            </Copy>
            <Copy measure={false} component="div">
              <ul>
                <li>women in over 50% of our leadership roles;</li>
                <li>more than 50 ethnicities speaking over 43 languages; and</li>
                <li>one in five of us speak a first language that’s not English.</li>
              </ul>
            </Copy>
          </Stack>
        </ContentWrapper>
        <BackgroundImageWrapper backgroundImage={diversity} />
      </Wrapper>
    </Stack>
  </Section>
);

const CommitmentToTeTiritiSection = (): JSX.Element => (
  <Section role="section">
    <Wrapper>
      <ContentWrapper>
        <Stack space={{ xs: 2, md: 4 }}>
          <Box marginBottom={4}>
            <Heading size={3} color="trueGreen">
              Our commitment to Te Tiriti o Waitangi
            </Heading>
          </Box>
          <Copy measure={false}>
            We understand health is a taonga and are committed to the principles of{' '}
            <Bold>partnership</Bold>, <Bold>participation</Bold> and <Bold>protection</Bold>, which
            underpin Te Tiriti o Waitangi relationships between the Government and Māori.
            Implementation of these principles helps us in our day-to-day work.
          </Copy>
          <Copy measure={false}>
            To understand our kaupapa, we encourage and enable our people to undertake Te Reo and
            Tikanga training. We partner with Auckland iwi Ngāti Whātua Ōrākei, who provide nib
            health insurance for their hapū members, helping improve health outcomes in a way that
            honours Māori values and spirituality. As part of this partnership, we’ve introduced a
            benefit for Rongoā, created employment pathways, set up kaiārahi and teamed up for
            events like Waitangi Day and Round the Bays.
          </Copy>
        </Stack>
      </ContentWrapper>
      <BackgroundImageWrapper backgroundImage={teTiritiOWaitangi} backgroundPosition="top" />
    </Wrapper>
  </Section>
);

const AboutNib = (): JSX.Element => (
  <Layout>
    <div>
      <Helmet>
        <title>About nib | nib</title>
        <meta
          name="description"
          content={
            'nib Group (nib) provides health and medical insurance to over 1.4 million Australian and New Zealand residents. We empower members to improve their wellbeing.'
          }
        />
      </Helmet>
      <HeroPanelSection />
      <AboutUsSection />
      <LinkTilesSection />
      <PartnersSection />
      <CommitmentToDiversitySection />
      <CommitmentToTeTiritiSection />
      <CareersSection />
      <NavigationSection />
    </div>
  </Layout>
);

export default metrics({ pageName: 'about-nib' })(AboutNib);
